import {
	config,
	EditablePage,
	EnvironmentContextProvider,
	ProvideAll,
} from '@ds-mgnl/marketing';
import { useLoaderData } from 'react-router-dom';
import type { LoaderFunction } from 'react-router-dom';
import {
	getMagnoliaBaseDamUrl,
	getMagnoliaBaseUrl,
} from '../util'; 

export const magnoliaPageLoader: LoaderFunction = async ({ request }) => {
	const url = new URL(request.url);
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		url.pathname;

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const assetCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/collateral-library/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const resourceCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/resources/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const insightCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/insights/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const legalCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/legal/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export function MagnoliaPage() {
	const { pageJson } = useLoaderData() as any;
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const magnoliaBaseDamUrl = getMagnoliaBaseDamUrl();

	return (
		<ProvideAll>
			<EnvironmentContextProvider
				value={{
					damHost: magnoliaBaseDamUrl!,
					appHost: magnoliaBaseUrl!,
					isInMagnolia: false,
				}}
			/>
			<EditablePage
				templateAnnotations={{}}
				content={pageJson}
				config={config}
			></EditablePage>
		</ProvideAll>
	);
}
