// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopNavTels-module_header-item_nzL {
    padding-left: 14px;
    padding-right: 14px;
}

.TopNavTels-module_header-items__xC {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

/* duplicated from "global nav" for single connect register link in desktop header */
.TopNavTels-module_primary-nav-link_kmQ {
    display: block;
    text-decoration: none;
    color: #293036; /* var(--color-gray-800) */
}
.TopNavTels-module_primary-menu-item-content_ebH {
    padding: 12px 14px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    cursor: pointer;
}
.TopNavTels-module_primary-menu-item-content_ebH:visited,
.TopNavTels-module_primary-menu-item-content_ebH:focus,
.TopNavTels-module_primary-menu-item-content_ebH:active,
.TopNavTels-module_primary-menu-item-content_ebH.TopNavTels-module_active_LoZ {
    background-color: #e0e4e7; /* var(--color-gray-100) */
    color: #293036; /* var(--color-gray-800) */
    text-decoration: none;
}
.TopNavTels-module_primary-menu-item-content_ebH:hover {
    color: #293036; /* var(--color-gray-800) */
    text-decoration: none;
    background-color: #f7f8f9; /* var(--color-gray-50) */
}`, "",{"version":3,"sources":["webpack://./src/components/TopNavTels/TopNavTels.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA,oFAAoF;AACpF;IACI,cAAc;IACd,qBAAqB;IACrB,cAAc,EAAE,0BAA0B;AAC9C;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;AACA;;;;IAII,yBAAyB,EAAE,0BAA0B;IACrD,cAAc,EAAE,0BAA0B;IAC1C,qBAAqB;AACzB;AACA;IACI,cAAc,EAAE,0BAA0B;IAC1C,qBAAqB;IACrB,yBAAyB,EAAE,yBAAyB;AACxD","sourcesContent":[".header-item {\n    padding-left: 14px;\n    padding-right: 14px;\n}\n\n.header-items {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 4px;\n}\n\n/* duplicated from \"global nav\" for single connect register link in desktop header */\n.primary-nav-link {\n    display: block;\n    text-decoration: none;\n    color: #293036; /* var(--color-gray-800) */\n}\n.primary-menu-item-content {\n    padding: 12px 14px;\n    display: flex;\n    flex-direction: row;\n    border-radius: 4px;\n    cursor: pointer;\n}\n.primary-menu-item-content:visited,\n.primary-menu-item-content:focus,\n.primary-menu-item-content:active,\n.primary-menu-item-content.active {\n    background-color: #e0e4e7; /* var(--color-gray-100) */\n    color: #293036; /* var(--color-gray-800) */\n    text-decoration: none;\n}\n.primary-menu-item-content:hover {\n    color: #293036; /* var(--color-gray-800) */\n    text-decoration: none;\n    background-color: #f7f8f9; /* var(--color-gray-50) */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-item": `TopNavTels-module_header-item_nzL`,
	"header-items": `TopNavTels-module_header-items__xC`,
	"primary-nav-link": `TopNavTels-module_primary-nav-link_kmQ`,
	"primary-menu-item-content": `TopNavTels-module_primary-menu-item-content_ebH`,
	"active": `TopNavTels-module_active_LoZ`
};
export default ___CSS_LOADER_EXPORT___;
