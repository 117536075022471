import type { MenuContent } from '../types';

// If the menu item has no secondary items, then the active state is determined by the primary path
// being an EXACT match to the URL. If the menu item has secondary items, then the active state is
// determined by the URL starting with the primary path.
const updateActiveContent = (
	menuContent: MenuContent[],
	activePath: string
) => {
	const trimmedPathname = activePath.replace(/\/$/, '');
	return menuContent.reduce<MenuContent[]>((acc, item) => {
		return [
			...acc,
			{
				...item,
				primary: {
					...item.primary,
					active:
						!item.isAssetLink && item.secondary.length
							? trimmedPathname.startsWith(item.primary.path.replace(/\/$/, ''))
							: trimmedPathname === item.primary.path.replace(/\/$/, ''),
				},
				secondary: item.secondary.map((secondaryItem) => ({
					...secondaryItem,
					active:
						!item.isAssetLink &&
						trimmedPathname.startsWith(secondaryItem.path.replace(/\/$/, '')),
				})),
			},
		];
	}, []);
};

export { updateActiveContent };
